var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass: "banner-ratio banner-ratio-main",
      attrs: {
        to: _vm.tag === "router-link" && _vm.data.relative_url,
        href: _vm.data.link || false,
        target: (_vm.data.link && _vm.data.target) || false,
      },
      on: {
        click: function ($event) {
          return _vm.onClick($event, _vm.data.relative_url)
        },
      },
    },
    [
      _c(
        "base-picture",
        { staticClass: "banner-img", attrs: { lazy: _vm.lazy } },
        [
          _c("base-img", {
            staticClass: "rounded",
            attrs: {
              lazy: _vm.lazy,
              src: _vm.getImageSrc(_vm.data.manifest.phone.image),
              srcset: _vm.getImageSrcset(_vm.data.manifest.phone.image),
              sizes: _vm.getImageSizes(),
              alt: _vm.data.name,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }